/**
     * 保留几位小数，去除0
     * @param {number} 值
     * @return {number}  返回值
 */
export function formatFixed(value, fraction = 2) {
  return (value).toFixed(fraction).replace(/[.]?0+$/, '')
}

/**
   * 等级评价
   * @param {number} 值
   * @return {string}  返回值
*/
export function formatLevel(value) {
  if (value >= 4 && value < 5) {
    return `<span style="color: #67c23a;">优</span>`
  } else if (value >= 3 && value < 4) {
    return `<span style="color: #5fb0ed;">良</span>`
  } else if (value >= 2 && value < 3) {
    return `<span style="color: #e68b35;">中</span>`
  } else if (value >= 1 && value < 2) {
    return `<span style="color: #e44545;">差</span>`
  } else {
    return `<span>-</span>`
  }
}

export function formatOrderStatus(status) {
  switch (status) {
    case -2:
      return `<span style="color: #FF2222;">超时</span>`;
    case -1:
      return `<span style="color: #FF2222;">已取消</span>`;
    case 0:
      return `<span style="color: #FF2222;">等待审批</span>`;
    case 1:
      return `<span style="color: #60DA47;">预约通过</span>`;
    case 2:
      return `<span style="color: #FF2222;">未通过</span>`;
    case 3:
      return `<span style="color: #60DA47;">使用中</span>`;
    case 4:
      return `<span style="color: #FF2222;">报障</span>`;
    case 5:
      return `<span style="color: #FF2222;">待消毒</span>`;
    case 6:
      return `<span style="color: #FF2222;">归还</span>`;
    case 7:
      return `<span style="color: #FF2222;">已维修</span>`;
    case 8:
      return `<span style="color: #60DA47;">已消毒</span>`;
    default:
      return "";
  }
}
export function formatEmpty(value) {
  return value || "";
}
/** 防抖函数
 * @param {function} 回调函数
 * @param {number} 节流时间
 * @param {boolean} 是否立即执行
 */
export function debounce(fn, delay = 200, immediate) {
  // immediate  是否立即执行
  let timeout = null
  return function () {
    const that = this
    const args = arguments
    clearTimeout(timeout)
    if (immediate) {
      const callNow = !timeout
      timeout = setTimeout(() => {
        timeout = null
      }, delay)
      // 立即执行
      if (callNow) fn.apply(that, args)
    } else {
      // 不会立即执行
      timeout = setTimeout(() => {
        fn.apply(that, args)
      }, delay)
    }
  }
}