<template>
	<div class="deviceDetails">
		<overflowYHidden>
			<div class="detalis" :style="{ 'background-image': 'url(' + bgHeader + ')' }">
				<div class="deviceHeader">
					<img :src="image" class="userImg" />
					<span class="name textEllipsis">{{ formatEmpty(detailsData.name) }}</span>
				</div>
				<div class="title">基本信息</div>
				<div class="item">
					<span class="itemLabel">型号</span>
					<span class="itemValue">{{ formatEmpty(detailsData.modelName) }}</span>
				</div>
				<div class="item">
					<span class="itemLabel">品牌</span>
					<span class="itemValue">{{ formatEmpty(detailsData.brandName) }}</span>
				</div>
				<div class="item">
					<span class="itemLabel">评价</span>
					<span class="itemValue"><van-rate v-model="detailsData.levelNum" :size="12" color="#ffd21e" void-icon="star" void-color="#eee" /></span>
				</div>
				<div class="item">
					<span class="itemLabel">{{$VUEAPPDEVICECODE()}}</span>
					<span class="itemValue">{{ formatEmpty(detailsData.code) }}</span>
				</div>
				<div class="item">
					<span class="itemLabel">卡片号</span>
					<span class="itemValue">{{ formatEmpty(detailsData.label) }}</span>
				</div>
				<div class="item">
					<span class="itemLabel">购入时间</span>
					<span class="itemValue">{{ formatDate(detailsData.purchaseDate) }}</span>
				</div>
				<div class="item">
					<span class="itemLabel">原值</span>
					<span class="itemValue">{{ formatEmpty(detailsData.price) }}元</span>
				</div>
				<div class="item">
					<span class="itemLabel">计费方式</span>
					<span class="itemValue">{{ $formatBillingType1(detailsData.billingType).name }}</span>
				</div>
				<div class="item">
					<span class="itemLabel">价格</span>
					<span class="itemValue">{{ formatEmpty(detailsData.sharePrice) }}元</span>
				</div>
			</div>
			<div class="detalis">
				<div class="item">
					<span class="itemLabel">申请科室</span>
					<span class="itemValue">{{ formatEmpty(detailsData.tenantName) }}</span>
				</div>
				<div class="item">
					<span class="itemLabel">入池时间</span>
					<span class="itemValue">{{ formatDate(detailsData.firstShareTime) }}</span>
				</div>
				<div class="item">
					<span class="itemLabel">预约时间</span>
					<span class="itemValue">{{ formatDate(detailsData.orderTime) }}</span>
				</div>
				<div class="item">
					<span class="itemLabel">取件地址</span>
					<span class="itemValue" @click="showDeviceMapDetail">{{ formatDate(detailsData.address) }}</span>
				</div>
			</div>
			<div class="detalis shareData" v-if="list.length > 0"><orderItem type="shareData" v-for="(item, index) in list" :key="index" :dataInfo="item"></orderItem></div>
		</overflowYHidden>

		<!--    <el-drawer-->
		<!--        :modal="false"-->
		<!--        size="calc(100% - 50px)"-->
		<!--        :visible.sync="showDetail"-->
		<!--        :with-header="false"-->
		<!--        direction="btt">-->

		<!--      <search-device-detail :device-detail="detailsData"></search-device-detail>-->

		<!--    </el-drawer>-->

		<el-dialog width="90%" title="" :visible.sync="showDetail">
			<div style="width: 100%;height: 600px"><search-device-detail :device-detail="detailsData"></search-device-detail></div>
		</el-dialog>
	</div>
</template>

<script>
import { formatEmpty } from '@/assets/JS/utils.js';
import overflowYHidden from '@/components/overflowYHidden';
import orderItem from '@/views/shareAndDispatch/device/deviceRepair/order/orderItem.vue';
import SearchDeviceDetail from '@views/searchDevice/searchDeviceDetail/searchDeviceDetail';
export default {
	components: { SearchDeviceDetail, overflowYHidden, orderItem },
	computed: {
		image() {
			if (this.$valueIsExist(this.detailsData, 'avatar') && this.detailsData['avatar'] != '') {
				let photo = this.detailsData['avatar'];
				return this.$replacePhoto(`${window.ipConfig.VUE_APP_BASEURL}/api/image/get?id=${this.$getPhotosId(photo)}&scale=0.1`);
			}
			return this.$replacePhoto('');
		}
	},
	data() {
		return {
			showDetail: false,
			formatEmpty: formatEmpty,
			photo: 'https://img0.baidu.com/it/u=1159755399,1068993229&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
			bgHeader: require('../../../../../static/iconImage/reservationListBgHeader.png'),
			detailsData: {},
			id: '',
			orderId: '',
			list: []
		};
	},
	created() {
		this.id = this.$route.query.id;
		this.orderId = this.$route.query.orderId;
		this.shareDeviceDetail();
		this.shareData();
	},
	methods: {
		showDeviceMapDetail: function() {
			this.showDetail = true;
		},

		shareDeviceDetail() {
			this.$api.deviceBorrowModule
				.shareDeviceDetail({
					deviceId: this.id
				})
				.then(res => {
					this.detailsData = res.data;
				})
				.catch(e => {});
		},
		shareData() {
			this.$api.deviceBorrowModule
				.shareData({
					id: this.orderId
				})
				.then(res => {
					this.list = res.data;
				})
				.catch(res => {});
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@styles/variables.scss';

.deviceDetails {
	padding: 10px;
	position: relative;
	height: 100%;
	flex-direction: column;
	.content {
	}
	.detalis {
		background: $background_color2;
		background-size: 100%;
		background-repeat: no-repeat;
		padding: 0 10px;
		border-radius: 8px;
		margin-bottom: 10px;
		.deviceHeader {
			padding: 24px 10px;
			box-sizing: content-box;
			display: flex;
			.userImg {
				width: 44px;
				height: 44px;
			}
			.name {
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto 0;
				padding-left: 20px;
				font-size: 18px;
				color: $color1;
				font-weight: bold;
			}
		}
		.title {
			font-size: 16px;
			color: $color1;
			font-weight: bold;
			padding-top: 10px;
			height: 7.65%;
		}
		.item {
			min-height: 38px;
			height: 7.65%;
			line-height: 38px;
			font-size: 14px;
			font-weight: 500;
			display: flex;
			justify-content: space-between;
			.itemLabel {
				display: inline-block;
				padding-left: 4px;
				width: 106px;
				color: $color3;
				text-align: left;
			}
		}
	}
	.shareData {
		padding: 16px 12px;
	}
	.deviceFooter {
		color: #ffffff;
		font-weight: bold;
		height: 40px;
		line-height: 40px;
		border-radius: 20px;
		text-align: center;
		background: #3e73fb;
		margin-top: 10px;
	}
}

::v-deep .el-dialog {
	display: flex;
	flex-direction: column;
	margin: 0 !important;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	/*height:600px;*/
	max-height: calc(100% - 200px);
	max-width: calc(100% - 30px);
}
::v-deep .el-dialog .el-dialog__body {
	flex: 1;
	overflow: auto;
}
</style>
